import reactIcon from "../assets/images/reacticon.png";
import NextjsIcon from "../assets/images/nextjsicon.png";
import reactnative from "../assets/images/reactnativeicon.png";
import html from "../assets/images/htmlicon.png";
import css from "../assets/images/cssicon.png";
import js from "../assets/images/jsicon.png";
import bootsrap from "../assets/images/bootstrapicon.png";
import tailwind from "../assets/images/tailwindicon.png";
import redux from "../assets/images/reduxicon.png";
import typescript from "../assets/images/typescripticon.png";
import NodeJs from "../assets/images/nodejs.png";
import Java from "../assets/images/java.png";
import php from "../assets/images/phpicon.png";
import clang from "../assets/images/cicon.png";
import Express from "../assets/images/expressjs.png";
import MongoDb from "../assets/images/mongoDb.png";
import Mysql from "../assets/images/mysql.png";
import firebase from "../assets/images/firebase.png";
import postgresql from "../assets/images/postgreSql.png";
import Vscode from "../assets/images/vscode.png";
import JetBrainsTools from "../assets/images/jetbrains.png";
import Figma from "../assets/images/figma.png";
import Photoshop from "../assets/images/photoshop.png";
import GitHub from "../assets/images/Github.png";
import Git from "../assets/images/git.png";
import Canva from "../assets/images/canva.png";
import Aws from "../assets/images/AWS.png";
import Proteus from "../assets/images/proteus.png";
import uwu from "../assets/images/uwu.png";
import jhc from "../assets/images/jhc.png";
import bosco from "../assets/images/bosco.png";
import sftskill1 from "../assets/images/sofiskill1.png";
import sftskill2 from "../assets/images/sofiskill2.png";
import sftskill3 from "../assets/images/sofiskill3.png";
import sftskill4 from "../assets/images/sofiskill4.png";
import sftskill5 from "../assets/images/sofiskill5.png";
import Project from "../assets/images/project.png";
import django from "../assets/images/django.png";
import healerz from "../assets/images/healerz.png";
import InfinitraX from "../assets/images/infinitrax.png";
import JVS from "../assets/images/JVS.png";

const frontendskills = [
  { name: "ReactJs", image: reactIcon },
  { name: "NextJs", image: NextjsIcon },
  { name: "Html5", image: html },
  { name: "CSS", image: css },
  { name: "Javascript", image: js },
  { name: "Boostrap", image: bootsrap },
  { name: "Tailwind", image: tailwind },
  { name: "Redux", image: redux },
  { name: "TypeScript", image: typescript },
  { name: "ReactNative", image: reactnative },
];

const backendendskills = [
  { name: "NodeJs", image: NodeJs },
  { name: "Java", image: Java },
  { name: "Php", image: php },
  { name: "C Lang", image: clang },
  { name: "ExpressJs", image: Express },
  // { name: "Django", image: django },
];
const DataBaseskills = [
  { name: "MongoDb", image: MongoDb },
  { name: "MySql", image: Mysql },
  { name: "PostgreSql", image: postgresql },
  { name: "Firebase", image: firebase },
];

const Toolsskills = [
  { name: "VsCode", image: Vscode },
  { name: "JetBrainsTools", image: JetBrainsTools },
  { name: "Figma", image: Figma },
  { name: "Photoshop", image: Photoshop },
  { name: "GitHub", image: GitHub },
  { name: "Git", image: Git },
  { name: "Canva", image: Canva },
  { name: "Aws", image: Aws },
  { name: "Proteus", image: Proteus },
];
const softskills = [
  { name: "Goal oriented", image: sftskill1 },
  { name: "Effective time management", image: sftskill2 },
  { name: "Effective communicator", image: sftskill3 },
  { name: "Leadership skills", image: sftskill4 },
  { name: "Fast learner", image: sftskill5 },
];

const PersonalInfo = [
  { name: "Name", content: "I.Powsihan" },
  { name: "Age", content: "24 Years Old" },
  { name: "City", content: "Nallur, Jaffna" },
  { name: "Nationality", content: "Srilankan" },
  { name: "Position", content: "Undergratuate (UWU)" },
  { name: "Email", content: "powsipowsihan07@gmail.com" },
  { name: "PhoneNo", content: "+94 0760072186" },
  { name: "Languages", content: "English,Tamil,Singala" },
];

const Language = [
  { language: "JavaScript", level: "95%" },
  { language: "Php", level: "85%" },
  { language: "Java", level: "75%" },
  { language: "Python", level: "60%" },
  { language: "C", level: "90%" },
];

const education = [
  {
    name: "Uva Wellasaa University Of Srilanka",
    period: "2021 - present",
    content1: "Bsc in Computer Science and Technology",
    image: uwu,
  },
  {
    name: "Jaffna Hindu College",
    period: "2011 - 2019",
    content1: "GCE (A/L) Examination - 2019",
    content2: "GCE (O/L) Examination - 2016",
    image: jhc,
  },
  {
    name: "St.John Bosco's Vidyalayam",
    period: "2006 - 2010",
    content1: "Grade 5 Scholarship Examination - 2010",
    image: bosco,
  },
];

const projects = [
  {
    name: "JVS",
    subname: "Vehicle Market Place Agent System",
    period: "2024 April- 2023 June",
    backimage: JVS,
    techstack: [
      { name: "NextJs", icon: NextjsIcon },
      { name: "NodeJs", icon: NodeJs },
      { name: "MongoDB", icon: MongoDb },
      { name: "Bootstrap", icon: bootsrap },
      { name: "Figma", icon: Figma },
    ],
    sourcecode:"",
    demovideo:"https://youtu.be/aAHO7hH2pqk?si=9nX6RrxweIGYalmC",
  },
  {
    name: "HealerZ",
    subname: "Medical System For Universities",
    period: "2023 May- 2023 Dec",
    backimage: healerz,
    techstack: [
      { name: "React", icon: reactIcon },
      { name: "Php", icon: php },
      { name: "MySql", icon: Mysql },
      { name: "Bootstrap", icon: bootsrap },
      { name: "Figma", icon: Figma },
    ],
    sourcecode:"https://github.com/Powsihan/HealerZ",
    demovideo:"https://www.linkedin.com/posts/powsihan-indrakumar-342554206_healerz-medicalsystem-innovation-activity-7133701422507847680-5OqN?utm_source=share&utm_medium=member_desktop",
  },
  {
    name: "InfinitraX",
    subname: "Inventory Management System",
    period: "2023 May- 2023 Dec",
    backimage: InfinitraX,
    techstack: [
      { name: "React", icon: reactIcon },
      { name: "Django", icon: django },
      { name: "MySql", icon: Mysql },
      { name: "Bootstrap", icon: bootsrap },
      { name: "Figma", icon: Figma },
    ],
    sourcecode:"https://github.com/Powsihan/InfinitraX",
    demovideo:"https://www.linkedin.com/posts/powsihan-indrakumar-342554206_infinitrax-webdevelopment-react-activity-7156995633558564864-lWag?utm_source=share&utm_medium=member_desktop",
  },
  {
    name: "To-Do-List",
    subname: "Task Doing Web Application",
    period: "2023 May- 2023 Dec",
    backimage: Project,
    techstack: [
      { name: "NextJs", icon: NextjsIcon },
      { name: "NodeJs", icon: NodeJs },
      { name: "MongoDB", icon: MongoDb },
      { name: "Tailwind", icon: tailwind },
    ],
    sourcecode:"https://github.com/Powsihan/To-do-app-Nextjs",
    demovideo:"",
  },
  
  {
    name: "Voyage_mate",
    subname: "Tourism Mobile Application",
    period: "2023 May- 2023 Dec",
    backimage: Project,
    techstack: [
      { name: "ReactNative", icon: reactnative },
      { name: "Django", icon: django },
      { name: "Mysql", icon: Mysql },
    ],
    sourcecode:"https://github.com/Powsihan/Discover_Lanka_FE_new",
    demovideo:"",
  },
  {
    name: "ReadO",
    subname: "BookReview Website",
    period: "2023 May- 2023 Dec",
    backimage: Project,
    techstack: [
      { name: "PHP", icon: php },
      { name: "Html", icon: html },
      { name: "Css", icon: css },
      { name: "Mysql", icon: Mysql },
    ],
    sourcecode:"https://github.com/Powsihan/BookReviewWebsite",
    demovideo:"https://youtu.be/gCYqc7rs36Q?si=m8OOJlQyDfSx4bFY",
  },
  {
    name: "MedZe",
    subname: "Medical System (CLI Project)",
    period: "2023 May- 2023 Dec",
    backimage: Project,
    techstack: [
      { name: "Java", icon: Java },
    ],
    sourcecode:"https://github.com/Powsihan/medze",
    demovideo:"https://youtu.be/auZlUoPtWJU?si=de9ElKDTuL27BmMN",
  },
  {
    name: "Electio",
    subname: "Medical System (CLI Project)",
    period: "2023 May- 2023 Dec",
    backimage: Project,
    techstack: [
      { name: "C Lang", icon: clang },
    ],
    sourcecode:"https://github.com/Powsihan/Electio",
    demovideo:"https://www.linkedin.com/posts/powsihan-indrakumar-342554206_clanguage-miniproject-uwu-activity-6940734874794283008-osmD?utm_source=share&utm_medium=member_desktop",
  },
  {
    name: "GardeniO",
    subname: "Automatic Watering System (Embedded Project)",
    period: "2023 May- 2023 Dec",
    backimage: Project,
    techstack: [
      { name: "C Lang", icon: clang },
      { name: "Proteus", icon: Proteus },
    ],
    sourcecode:"https://github.com/Powsihan/Automatic-Watering-System---Embedded",
    demovideo:"https://youtu.be/7J0_e9yNj8w?si=hsMzDuNS-DA6aHWg",
  },
];

export {
  frontendskills,
  backendendskills,
  DataBaseskills,
  Toolsskills,
  PersonalInfo,
  Language,
  education,
  softskills,
  projects,
};
