import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryButton from "../components/PrimaryButton";


function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const scrollToContactUs = () => {
    const contactUsSection = document.getElementById("contact");
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <nav
        className={`navbar navbar-dark bg-dark navbar-expand-lg shadow top Navbar-Home ${
          menuOpen ? "open" : "close"
        }`}
      >
        <div className="container-fluid px-5">
          <a className="navbar-brand" href="https://github.com/Powsihan">
            {/* <h2>Powsi</h2> */}
            <img src={logo} alt=""/>
          </a>
          <div className="navbar-toggler" onClick={toggleMenu}>
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </div>
          <div
            className={`navbar-collapse collapse justify-content-end ${
              menuOpen ? "show" : ""
            }`}
          >
            <ul className="navbar-nav gap-2 pe-3">
              <li className="nav-item nav-link nav-hover">
                <a className="nav-link" href="#home">
                  <span className="lettnav">Home</span>
                </a>
              </li>
              <li className="nav-item nav-link nav-hover">
                <a className="nav-link" href="#about">
                  <span className="lettnav">About</span>
                </a>
              </li>
              <li className="nav-item nav-link nav-hover">
                <a className="nav-link" href="#skills">
                  <span className="lettnav">Skills</span>
                </a>
              </li>
              <li className="nav-item nav-link nav-hover">
                <a className="nav-link" href="#projects">
                  <span className="lettnav">Projects</span>
                </a>
              </li>
              {windowSize[0] <= 800 ? (
                <li className="nav-item nav-link nav-hover">
                  <a className="nav-link" href="#contact">
                    <span className="lettnav">Contact Me</span>
                  </a>
                </li>
              ) : (
                <li></li>
              )}
            </ul>
            {windowSize[0] >= 800 ? (
              <div>
                <PrimaryButton label="Contact Me" onClick={scrollToContactUs}/>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
