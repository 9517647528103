import React, { useState } from "react";
import Navbar from "../../layouts/Navbar";
import Powsi from "../../assets/images/powsi.png";
import powsiabout from "../../assets/images/powsiabout2.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  backendendskills,
  DataBaseskills,
  education,
  frontendskills,
  Language,
  PersonalInfo,
  projects,
  softskills,
  Toolsskills,
} from "../../data/data";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import { IconButton } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import PrimaryButton from "../../components/PrimaryButton";

function Home() {
  const [activeSection, setActiveSection] = useState("frontend");
  const [activeaboutSection, setActiveAboutSection] = useState("personalInfo");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3zb4pgk",
        "template_h3hb0mq",
        e.target,
        "rCIGXe-UPN0HGbwWI"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          toast.success("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed to send message, please try again later");
        }
      );
  };

  const renderSkillsSection = () => {
    switch (activeSection) {
      case "frontend":
        return (
          <div
            className="Skill-content-Container container-fluid"
            id="frontend"
          >
            <h1 className="row ps-3 pt-3">Frontend</h1>
            <hr />
            <div className="row">
              {frontendskills?.map((data) => (
                <div
                  className="col d-flex flex-column align-items-center justify-content-center pb-2"
                  key={data?.name}
                >
                  <img src={data.image} className="pe-2" alt={data?.name} />
                  <h6>{data?.name}</h6>
                </div>
              ))}
            </div>
          </div>
        );
      case "backend":
        return (
          <div className="Skill-content-Container container-fluid" id="Backend">
            <h1 className="row ps-3 pt-3">Backend</h1>
            <hr />
            <div className="row">
              {backendendskills?.map((data) => (
                <div
                  className="col d-flex flex-column align-items-center justify-content-center pb-2"
                  key={data?.name}
                >
                  <img src={data?.image} className="pe-2" alt={data?.name} />
                  <h6>{data?.name}</h6>
                </div>
              ))}
            </div>
            <h1 className="row ps-3 pt-3">DataBase</h1>
            <hr />
            <div className="row">
              {DataBaseskills?.map((data) => (
                <div
                  className="col d-flex flex-column align-items-center justify-content-center pb-2"
                  key={data?.name}
                >
                  <img src={data.image} className="pe-2" alt={data?.name} />
                  <h6>{data?.name}</h6>
                </div>
              ))}
            </div>
          </div>
        );
      case "tools":
        return (
          <div className="Skill-content-Container container-fluid" id="Tools">
            <h1 className="row ps-3 pt-3">Tools</h1>
            <hr />
            <div className="row">
              {Toolsskills?.map((data) => (
                <div
                  className="col d-flex flex-column align-items-center justify-content-center pb-2"
                  key={data?.name}
                >
                  <img src={data.image} className="pe-2" alt={data?.name} />
                  <h6>{data?.name}</h6>
                </div>
              ))}
            </div>
          </div>
        );
      case "softskill":
        return (
          <div className="Skill-content-Container container-fluid" id="Tools">
            <h1 className="row ps-3 pt-3">SoftSkills</h1>
            <hr />
            <div className="row">
              {softskills?.map((data) => (
                <div
                  className="col d-flex flex-column align-items-center justify-content-center pb-2"
                  key={data?.name}
                >
                  <img src={data?.image} className="pe-2" alt={data?.name} />
                  <h6 style={{ fontSize: "20px" }}>{data?.name}</h6>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderAboutSection = () => {
    switch (activeaboutSection) {
      case "personalInfo":
        return (
          <div
            className="Skill-content-Container container-fluid"
            id="personalInfo"
          >
            <div className="pt-4 pb-4">
              {PersonalInfo?.map((data) => (
                <div className="pe-lg-5 ps-lg-5">
                  <div className="d-flex justify-content-between flex-wrap gap-2">
                    <h4>{data?.name}</h4>
                    <h5
                      className="text-wrap w-100"
                      style={{ wordWrap: "break-word" }}
                    >
                      {data?.content}
                    </h5>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        );

      case "education":
        return (
          <div
            className="Skill-content-Container container-fluid"
            id="personalInfo"
          >
            <div className="pt-4 pb-4">
              {education?.map((data) => (
                <div>
                  <div className="education-section ps-2 pe-2 row">
                    <div className="col-lg-10 col-md-12 col-sm-12">
                      <h4>{data?.name}</h4>
                      <h5>{data?.period}</h5>
                      <h6>{data?.content1}</h6>
                      <h6>{data?.content2}</h6>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                      <img src={data?.image} style={{ width: "100px" }} />
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        );

      case "languages":
        return (
          <div
            className="Skill-content-Container container-fluid"
            id="personalInfo"
          >
            <div className="pe-lg-5 ps-lg-5 pt-5 pb-5">
              {Language?.map((data) => (
                <div class="container pb-4">
                  <h5>{data?.language}</h5>
                  <div class="progress bg-dark">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: `${data.level}`,
                        backgroundColor: "rgba(66, 245, 236, 0.616)",
                        color: "black",
                        fontWeight: "700",
                      }}
                    >
                      {data?.level}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      case "interst":
        return (
          <div
            className="Skill-content-Container container-fluid"
            id="personalInfo"
          >
            <div className="pe-lg-5 ps-lg-5 pt-5 pb-5">
              <h6 className="row ps-3 pt-3">Games</h6>
              <ul className="d-flex justify-content-between pt-3 flex-wrap gap-2 ">
                <li>Cricket</li>
                <li>Hockey</li>
                <li>Badminton</li>
                <li>Chess</li>
              </ul>
              <hr />
              <h6 className="row ps-3 pt-3">Photography</h6>
              <hr />
              <h6 className="row ps-3 pt-3">Coding</h6>
              <hr />
            </div>
          </div>
        );
    }
  };
  return (
    <>
      <div className="Home-Background">
        <Navbar />
        <motion.section
          className="home"
          id="home"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2 }}
        >
          <div className="row d-flex min-vh-100 Home-Page-Content" id="home">
            <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center Home-Page-Content-details">
              <div>
                <h2>
                  Building Digital
                  <br /> Experiences That <br />
                  Inspire
                </h2>
                <p className="ps-2 ps-lg-0">
                  Passionate FullStack Developer | Transforming Ideas into{" "}
                  <br /> Seamless and Visually Stunning Web Solutions
                </p>
                <div className="d-flex gap-lg-3 justify-content-lg-start justify-content-center gap-2">
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/in/powsihan-indrakumar-342554206?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                      )
                    }
                  >
                    <LinkedInIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/POWSI07?mibextid=LQQJ4d"
                      )
                    }
                  >
                    <FacebookIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                    onClick={() => window.open("https://github.com/Powsihan")}
                  >
                    <GitHubIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/powsi_07?igsh=cGkzMGxrbWd5anVp&utm_source=qr"
                      )
                    }
                  >
                    <InstagramIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                    // onClick={() => window.open('https://github.com/Powsihan')}
                  >
                    <MailIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                  >
                    <CallIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-end justify-content-end home-image-profile">
              <img src={Powsi} alt="" width={600} />
            </div>
          </div>
        </motion.section>
        <div
          className="container-fluid min-vh-100 Home-Page-Content About-content pt-5"
          id="about"
        >
          <div className="d-flex flex-column ps-5 pt-5">
            <h1>About me</h1>
            <div className="custom-hr" />
          </div>
          <p className="ps-5 pe-5 pt-3">
            I’m Powsihan, a 24-year-old undergraduate student currently pursuing
            a Bsc in Computer Science and Technology degree program at Uva
            Wellassa University of Sri Lanka.
          </p>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start align-items-center">
              <img src={powsiabout} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex align-items-center justify-content-center flex-wrap gap-3 mt-4">
                <div
                  className={`about-section-head ${
                    activeaboutSection === "personalInfo" ? "active" : ""
                  }`}
                  onClick={() => setActiveAboutSection("personalInfo")}
                >
                  Personal Info
                </div>
                <div
                  className={`about-section-head ${
                    activeaboutSection === "education" ? "active" : ""
                  }`}
                  onClick={() => setActiveAboutSection("education")}
                >
                  Education
                </div>
                <div
                  className={`about-section-head ${
                    activeaboutSection === "languages" ? "active" : ""
                  }`}
                  onClick={() => setActiveAboutSection("languages")}
                >
                  Languages
                </div>
                <div
                  className={`about-section-head ${
                    activeaboutSection === "interst" ? "active" : ""
                  }`}
                  onClick={() => setActiveAboutSection("interst")}
                >
                  Interest
                </div>
              </div>
              <div className="ps-5 pe-5 pt-4 pb-4">{renderAboutSection()}</div>
            </div>
          </div>
        </div>
        <div className="row min-vh-100 Home-Page-Content pt-5" id="skills">
          {/* <div className="d-flex flex-column ps-5 pt-5">
            <h1 className="main-section-heading">Skills</h1>
            <div className="custom-hr" style={{width:'50px'}}/>
          </div> */}
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-center container-fluid">
            <div className="row ps-5 pe-5">
              <div className="col-lg-6 col-md-6 col-sm-3">
                <div
                  className={`skill-card mb-3 ${
                    activeSection === "frontend" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("frontend")}
                >
                  Frontend
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-3">
                <div
                  className={`skill-card mb-3 ${
                    activeSection === "backend" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("backend")}
                >
                  Backend
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-3">
                <div
                  className={`skill-card mb-3 ${
                    activeSection === "tools" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("tools")}
                >
                  Tools
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-3">
                <div
                  className={`skill-card mb-3 ${
                    activeSection === "softskill" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("softskill")}
                >
                  Soft Skills
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center pe-4 ps-4">
            {renderSkillsSection()}
          </div>
        </div>

        <div
          className="container-fluid min-vh-100 Home-Page-Content pt-5"
          id="projects"
        >
          <div className="d-flex flex-column ps-5 pt-5">
            <h1 className="main-section-heading">Projects</h1>
            <div className="custom-hr" style={{ width: "100px" }} />
          </div>
          <div className="pt-5 container-fluid">
            <div className="row">
              {projects?.map((data) => (
                <div className="col-lg-6 col-sm-12 pe-lg-5 ps-lg-5">
                  <div className="Project-Card ps-2 pe-2 mb-5">
                    <div className="pt-2 pb-2">
                      <img
                        src={data?.backimage}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fill",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap gap-sm-2">
                      <h1>{data?.name}</h1>
                      <h3>{data?.period}</h3>
                    </div>
                    <h2>{data?.subname}</h2>
                    <hr />
                    <div>
                      <h4>Tech Stack</h4>
                      <div className="row d-flex justify-content-center">
                        {(data?.techstack || [])?.map((tech) => (
                          <div className="col d-flex flex-column align-items-center justify-content-center pb-2 tech-image">
                            <img
                              src={tech?.icon}
                              alt=""
                              style={{ width: "50px" }}
                            />
                            <h6>{tech?.name}</h6>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-end gap-4 pb-4 pt-2">
                      <PrimaryButton
                        label={"Source Code"}
                        onClick={() =>{data?.sourcecode? window.open(data.sourcecode):toast.info("Source Code not Available")}}
                      />
                      <PrimaryButton
                        label={"Demo Video"}
                        onClick={() =>{data?.demovideo? window.open(data.demovideo):toast.info("Demo Video not Available")}}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="container-fluid min-vh-100 Home-Page-Content pt-5"
          id="contact"
        >
          <div className="d-flex flex-column ps-5 pt-5">
            <h1 className="main-section-heading">Contact and FeedBack</h1>
            <div className="custom-hr" style={{ width: "290px" }} />
          </div>
          <div className="row pt-3">
            <div className="col-lg-6 col-md-6 col-sm-12 ps-5 pe-5 mb-3">
              <div className="Contact-Box p-5">
                <div className="d-flex justify-content-start align-items-center gap-3 mb-2">
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                  >
                    <MailIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <h5
                    className="text-wrap w-75"
                    style={{ wordWrap: "break-word" }}
                  >
                    powsipowsihan07@gmail.com
                  </h5>
                </div>
                <div className="d-flex justify-content-start align-items-center gap-3 mb-2">
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                  >
                    <CallIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <h5>+94 076 0072 186</h5>
                </div>
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <IconButton
                    sx={{
                      color: "rgb(150, 208, 255)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                  >
                    <LocationOnIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                  <h5>33/8 RajeswariRoad, Nayanmarkaddu, Jaffna</h5>
                </div>
                <div className="row pb-4 pt-4">
                  <div className="col d-flex justify-content-center align-items-center">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31514.05263393508!2d80.0071664382629!3d9.661508064501747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae5f7de2061e7ef%3A0x86c7e7b3be16f74b!2sNayanmarkaddu%2C%20Jaffna!5e0!3m2!1sen!2slk!4v1625673429470!5m2!1sen!2slk"
                      width="600"
                      height="450"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <form onSubmit={handleSubmit}>
                <div className="Contact-Box p-5">
                  <div className="form-group mb-3">
                    <label htmlFor="input-field" className="Text-input-label">
                      Name
                    </label>
                    <input
                      className="form-control bg-dark text-white"
                      placeholder={"Enter Your Name"}
                      id="name"
                      name="name"
                      value={formData?.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="input-field" className="Text-input-label">
                      Email
                    </label>
                    <input
                      className="form-control bg-dark text-white"
                      placeholder={"Enter Your Email"}
                      id="email"
                      name="email"
                      value={formData?.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group mb-5">
                    <label htmlFor="input-field" className="Text-input-label">
                      Message
                    </label>
                    <textarea
                      className="form-control bg-dark text-white"
                      id="message"
                      name="message"
                      rows="3"
                      value={formData?.message}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="d-flex justify-content-end pe-2">
                    <PrimaryButton label={"Submit Message"} width={170} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer Footer-section pt-3 pb-2 d-flex align-items-center justify-content-center">
          © 2024 Powsihan @ All Rights Reserved
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Home;
