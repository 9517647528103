import React from 'react';

const PrimaryButton = ({ onClick, label,width }) => {
  return (
    <button className='btn btn-primary' onClick={onClick} style={{width:width}}>
      {label}
    </button>
  );
};

export default PrimaryButton;
